export const environment = {
    production: true,
    console: false,
    API_URL: "https://api.apiacpos.com/2_4_0_bridge/api/",
    CODIGO_APLICACION: "CUSTOMAN",
    ID_APLICACION: "19",
    chClaveEmpresa: "REALESCUELA",
    TICKETS_URL: "https://ventas.realescuela.org/",
    chProyectoOnLine: "VENTAONLINE",
    chEntornoConexion: "PRODUCCION",
    chNombreProyecto: "CUSTOMMAN",
    chOrigenConexion: "CUSTOMMAN",
    NUMSERIE: "CUSTO$A$SZ45$A$MMAN$A$23g!."
};

